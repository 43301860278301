// ======================
// ConnectNewStorageWizardStorageConnectionStep
// ======================

import { Box, Button, Card, Chip, Dialog, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { StepperNavButtons } from "../../../../common/stepper/StepperComponents";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../../core/dialog/DialogService";
import { DialogTopBar } from "../../../core/dialog/DialogComponents";
import { useListCdmDataAccessNodes } from "../../unstructured_data_hooks";
import { useCurrentProjectID } from "../../../project/CurrentProjectState";
import { CdmDataAccessNodeInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { QueryResultWrapper } from "../../../core/data/QueryResultWrapper";
import { useConnectNewStorageWizardState } from "./ConnectNewStorageWizardState";
import { AddCdmStorage } from "gc-web-proto/galaxycompletepb/apipb/cdmapipb/cdm_api_pb";
import Grid from "@mui/material/Grid2";
import { EditIcon } from "../../../../common/CommonIcons";
import { DeleteIcon } from "../../../../common/CommonIcons";
import { KeyValuePair } from "../../../../common/text/CommonTypeFormats";

interface ConnectNewStorageWizardStorageConnectionStepProps {}

export const ConnectNewStorageWizardStorageConnectionStep: React.FC<ConnectNewStorageWizardStorageConnectionStepProps> = (p) => {
    const {} = p;
    const projectId = useCurrentProjectID();
    const dataAccessNodes = useListCdmDataAccessNodes(projectId, 1, 1000);
    const { connectionsList } = useConnectNewStorageWizardState();

    return (
        <Stack direction={"column"} spacing={2}>
            <Box>
                <Typography variant={"h5"}>{"Add Storage Connections"}</Typography>
                <Typography>{"Select storage protocols to configure your connection for NFS, SMB, or S3."}</Typography>
            </Box>
            <QueryResultWrapper queryResult={dataAccessNodes}>
                <Stack direction={"row"} spacing={2}>
                    <AddConnectionButton connectionType="NFS" dataAccessNodes={dataAccessNodes.data?.itemsList} />
                    <AddConnectionButton connectionType="SMB" dataAccessNodes={dataAccessNodes.data?.itemsList} />
                    <AddConnectionButton connectionType="S3" dataAccessNodes={dataAccessNodes.data?.itemsList} />
                </Stack>
            </QueryResultWrapper>
            <Grid container spacing={2}>
                {connectionsList.map((connection) => (
                    <Grid size={{ xs: 12, md: 6, lg: 4 }} key={connection.getAddress()}>
                        <StorageConnectionCard connection={connection.toObject()} dataAccessNodes={dataAccessNodes.data?.itemsList} />
                    </Grid>
                ))}
            </Grid>
            <StepperNavButtons
                nextButtonProps={{
                    disabled: connectionsList.length === 0,
                }}
            />
        </Stack>
    );
};

interface StorageConnectionCardProps {
    connection: AddCdmStorage.Request.StorageConnection.AsObject;
    dataAccessNodes: Array<CdmDataAccessNodeInfo.AsObject>;
}

const StorageConnectionCard: React.FC<StorageConnectionCardProps> = (p) => {
    const { connection, dataAccessNodes } = p;
    const connectionType = connection.nfs ? "NFS" : connection.smb ? "SMB" : connection.s3 ? "S3" : "Unknown";
    const { removeConnection } = useConnectNewStorageWizardState();
    const dataAccessNodeName = dataAccessNodes.find((node) => node.deployment.systemId === connection.nodeId)?.deployment.systemName;
    return (
        <Card>
            <Box p={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                    <ConnectionTypeChip connection={connection} />
                    <Stack direction="row" spacing={1}>
                        <EditConnectionButton connection={connection} dataAccessNodes={dataAccessNodes} connectionType={connectionType as ConnectionType} />
                        <IconButton size="small" onClick={() => removeConnection(connection.address)}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </Stack>
                <KeyValuePair label={"DataManager Node"} value={dataAccessNodeName} />
                <KeyValuePair label={"IP Address"} value={connection.address} />
                <KeyValuePair label={"Port"} value={connection.port} />
            </Box>
        </Card>
    );
};

type ConnectionType = "NFS" | "SMB" | "S3";

interface AddConnectionButtonProps {
    connectionType: ConnectionType;
    dataAccessNodes: Array<CdmDataAccessNodeInfo.AsObject>;
}

const AddConnectionButton: React.FC<AddConnectionButtonProps> = (p) => {
    const { connectionType, dataAccessNodes } = p;
    const dialogState = useDialogState();
    const { connectionsList } = useConnectNewStorageWizardState();

    const getConnectionDisabled = () => {
        if (connectionType === "SMB") {
            return !!connectionsList.find((c) => c.getSmb() !== undefined);
        }
        if (connectionType === "S3") {
            return !!connectionsList.find((c) => c.getS3() !== undefined);
        }
        if (connectionType === "NFS") {
            return !!connectionsList.find((c) => c.getNfs() !== undefined);
        }
        return false;
    };

    return (
        <>
            <Button disabled={getConnectionDisabled()} onClick={dialogState.open} variant={"outlined"}>
                {`Add ${connectionType} Connection`}
            </Button>
            {dialogState.isOpen && <ConnectionDialog dialogState={dialogState} connectionType={connectionType} dataAccessNodes={dataAccessNodes} />}
        </>
    );
};

interface EditConnectionButtonProps {
    connection: AddCdmStorage.Request.StorageConnection.AsObject;
    dataAccessNodes: Array<CdmDataAccessNodeInfo.AsObject>;
    connectionType: ConnectionType;
}

const EditConnectionButton: React.FC<EditConnectionButtonProps> = (p) => {
    const { connection, dataAccessNodes, connectionType } = p;
    const dialogState = useDialogState();
    return (
        <>
            <IconButton onClick={dialogState.open}>
                <EditIcon fontSize="small" />
            </IconButton>
            {dialogState.isOpen && (
                <ConnectionDialog dialogState={dialogState} connectionType={connectionType} dataAccessNodes={dataAccessNodes} connection={connection} />
            )}
        </>
    );
};

interface ConnectionDialogProps {
    connectionType: ConnectionType;
    dataAccessNodes: Array<CdmDataAccessNodeInfo.AsObject>;
    connection?: AddCdmStorage.Request.StorageConnection.AsObject;
    dialogState: DialogState;
}

const ConnectionDialog: React.FC<ConnectionDialogProps> = (p) => {
    const { connectionType, dataAccessNodes, connection, dialogState } = p;
    const { connectionsList } = useConnectNewStorageWizardState();
    const fullScreen = useShouldDialogFullScreen();
    const { addConnection, editConnection } = useConnectNewStorageWizardState();
    const [selectedNode, setSelectedNode] = useState<string>(connection?.nodeId || connectionsList[0]?.getNodeId() || "");
    const [ipAddress, setIpAddress] = useState(connection?.address || "");
    const [port, setPort] = useState(connection?.port || "");
    const [smbFields, setSmbFields] = useState<AddCdmStorage.Request.StorageConnection.SMBConnection.AsObject>({
        username: "",
        password: "",
        domain: "",
    });
    const [s3Fields, setS3Fields] = useState<AddCdmStorage.Request.StorageConnection.S3Connection.AsObject>({
        accessKeyId: "",
        secretAccessKey: "",
        sessionToken: "",
        roleArn: "",
        externalId: "",
    });

    const handleAddConnection = () => {
        const connection = new AddCdmStorage.Request.StorageConnection().setAddress(ipAddress).setPort(Number(port)).setNodeId(selectedNode);
        if (connectionType === "SMB") {
            connection.setSmb(
                new AddCdmStorage.Request.StorageConnection.SMBConnection()
                    .setDomain(smbFields.domain)
                    .setPassword(smbFields.password)
                    .setUsername(smbFields.username)
            );
        }
        if (connectionType === "S3") {
            connection.setS3(
                new AddCdmStorage.Request.StorageConnection.S3Connection()
                    .setAccessKeyId(s3Fields.accessKeyId)
                    .setSecretAccessKey(s3Fields.secretAccessKey)
                    .setSessionToken(s3Fields.sessionToken)
                    .setRoleArn(s3Fields.roleArn)
                    .setExternalId(s3Fields.externalId)
            );
        }
        if (connectionType === "NFS") {
            connection.setNfs(new AddCdmStorage.Request.StorageConnection.NFSConnection());
        }

        addConnection(connection);
        dialogState.close();
    };

    const handleEditConnection = () => {
        const connection = new AddCdmStorage.Request.StorageConnection().setAddress(ipAddress).setPort(Number(port)).setNodeId(selectedNode);
        if (connectionType === "SMB") {
            connection.setSmb(
                new AddCdmStorage.Request.StorageConnection.SMBConnection()
                    .setDomain(smbFields.domain)
                    .setPassword(smbFields.password)
                    .setUsername(smbFields.username)
            );
        }
        if (connectionType === "S3") {
            connection.setS3(
                new AddCdmStorage.Request.StorageConnection.S3Connection()
                    .setAccessKeyId(s3Fields.accessKeyId)
                    .setSecretAccessKey(s3Fields.secretAccessKey)
                    .setSessionToken(s3Fields.sessionToken)
                    .setRoleArn(s3Fields.roleArn)
                    .setExternalId(s3Fields.externalId)
            );
        }
        if (connectionType === "NFS") {
            connection.setNfs(new AddCdmStorage.Request.StorageConnection.NFSConnection());
        }

        editConnection(connection);
        dialogState.close();
    };

    const dialogAddButtonDisabled = !selectedNode || !ipAddress;

    return (
        <Dialog fullScreen={fullScreen} maxWidth={"sm"} fullWidth open={dialogState.isOpen} onClose={dialogState.close}>
            <DialogTopBar divider dialogState={dialogState} title={`Add ${connectionType} Connection`} />
            <Stack spacing={2} p={2}>
                <FormControl fullWidth>
                    <InputLabel variant={"filled"} id={"cdm-node-label"}>
                        {"Data Access Node"}
                    </InputLabel>
                    <Select variant={"filled"} labelId={"cdm-node-label"} value={selectedNode} onChange={(e) => setSelectedNode(e.target.value)}>
                        {dataAccessNodes.map((node) => (
                            <MenuItem key={node.deployment.systemId} value={node.deployment.systemId}>
                                {node.deployment.systemName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField variant={"filled"} fullWidth label={"IP Address"} value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} />
                <TextField variant={"filled"} fullWidth label={"Port"} value={port} type={"number"} onChange={(e) => setPort(e.target.value)} />
                {connectionType === "SMB" && (
                    <Stack direction={"column"} spacing={2}>
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Username"}
                            value={smbFields.username}
                            onChange={(e) => setSmbFields({ ...smbFields, username: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Password"}
                            value={smbFields.password}
                            onChange={(e) => setSmbFields({ ...smbFields, password: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Domain"}
                            value={smbFields.domain}
                            onChange={(e) => setSmbFields({ ...smbFields, domain: e.target.value })}
                        />
                    </Stack>
                )}
                {connectionType === "S3" && (
                    <Stack direction={"column"} spacing={2}>
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Access Key ID"}
                            value={s3Fields.accessKeyId}
                            onChange={(e) => setS3Fields({ ...s3Fields, accessKeyId: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Secret Access Key"}
                            value={s3Fields.secretAccessKey}
                            onChange={(e) => setS3Fields({ ...s3Fields, secretAccessKey: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Session Token"}
                            value={s3Fields.sessionToken}
                            onChange={(e) => setS3Fields({ ...s3Fields, sessionToken: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Role ARN"}
                            value={s3Fields.roleArn}
                            onChange={(e) => setS3Fields({ ...s3Fields, roleArn: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"External ID"}
                            value={s3Fields.externalId}
                            onChange={(e) => setS3Fields({ ...s3Fields, externalId: e.target.value })}
                        />
                    </Stack>
                )}
                <Stack direction={"row"} spacing={2}>
                    <Button variant={"contained"} disabled={dialogAddButtonDisabled} onClick={connection ? handleEditConnection : handleAddConnection}>
                        {"Verify And Add"}
                    </Button>
                    <Button variant={"outlined"} color={"neutral"} onClick={dialogState.close}>
                        {"Cancel"}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    );
};

interface ConnectionTypeChipProps {
    connection: AddCdmStorage.Request.StorageConnection.AsObject;
}

const ConnectionTypeChip: React.FC<ConnectionTypeChipProps> = (p) => {
    const { connection } = p;

    const getConnectionInfo = () => {
        if (connection.smb) return { type: "SMB", color: "info" as const };
        if (connection.s3) return { type: "S3", color: "success" as const };
        if (connection.nfs) return { type: "NFS", color: "warning" as const };
        return { type: "Unknown", color: "default" as const };
    };

    const { type, color } = getConnectionInfo();

    return <Chip label={type} size="small" color={color} variant="filled" />;
};
