export const getBrowserTimezone = () => {
    return new Date()
        .toLocaleDateString("en-US", {
            day: "2-digit",
            timeZoneName: "long",
        })
        .slice(4);
};

export const getMinutesFromTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return hours * 60 + minutes;
};

export const createDateObjectFromMinutes = (minutes: number) => {
    const hours = minutes / 60 === 24 ? 0 : Math.floor(minutes / 60);
    const mins = minutes % 60;
    return new Date(0, 0, 0, hours, mins);
};

/**
 * Validates if a string is a valid cron expression.
 * Supports standard cron format with 5 fields: minute hour day-of-month month day-of-week
 *
 * Valid values:
 * - Minutes: 0-59
 * - Hours: 0-23
 * - Day of Month: 1-31
 * - Month: 1-12 or JAN-DEC
 * - Day of Week: 0-6 or SUN-SAT (0 and 7 represent Sunday)
 *
 * Special characters:
 * - *: any value
 * - ,: value list separator
 * - -: range of values
 * - /: step values
 *
 * @param cronExpression - The cron expression to validate
 * @returns boolean indicating if the expression is valid
 */
export const getIsValidCronExpression = (cronExpression: string): boolean => {
    // If empty or undefined, return false
    if (!cronExpression) {
        return false;
    }

    // Split the expression into its components
    const fields = cronExpression.trim().split(/\s+/);

    // Cron should have exactly 5 fields
    if (fields.length !== 5) {
        return false;
    }

    // Regular expressions for each field
    const patterns = {
        minute: /^(\*|([0-9]|[1-5][0-9])(-([0-9]|[1-5][0-9]))?(,([0-9]|[1-5][0-9])(-([0-9]|[1-5][0-9]))?)*|([\*]|([0-9]|[1-5][0-9]))\/([0-9]|[1-5][0-9]))$/,
        hour: /^(\*|([0-9]|1[0-9]|2[0-3])(-([0-9]|1[0-9]|2[0-3]))?(,([0-9]|1[0-9]|2[0-3])(-([0-9]|1[0-9]|2[0-3]))?)*|(\*|([0-9]|1[0-9]|2[0-3]))\/([0-9]|1[0-9]|2[0-3]))$/,
        dayOfMonth:
            /^(\*|([1-9]|[12][0-9]|3[01])(-([1-9]|[12][0-9]|3[01]))?(,([1-9]|[12][0-9]|3[01])(-([1-9]|[12][0-9]|3[01]))?)*|(\*|([1-9]|[12][0-9]|3[01]))\/([1-9]|[12][0-9]|3[01]))$/,
        month: /^(\*|(([1-9]|1[0-2])|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(-(([1-9]|1[0-2])|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?(,(([1-9]|1[0-2])|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(-(([1-9]|1[0-2])|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?)*|(\*|(([1-9]|1[0-2])|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))\/([1-9]|1[0-2]))$/i,
        dayOfWeek:
            /^(\*|([0-6]|SUN|MON|TUE|WED|THU|FRI|SAT)([-]([0-6]|SUN|MON|TUE|WED|THU|FRI|SAT))?(,([0-6]|SUN|MON|TUE|WED|THU|FRI|SAT)([-]([0-6]|SUN|MON|TUE|WED|THU|FRI|SAT))?)*|(\*|([0-6]|SUN|MON|TUE|WED|THU|FRI|SAT))\/([0-6]))$/i,
    };

    // Test each field against its corresponding pattern
    return (
        patterns.minute.test(fields[0]) &&
        patterns.hour.test(fields[1]) &&
        patterns.dayOfMonth.test(fields[2]) &&
        patterns.month.test(fields[3]) &&
        patterns.dayOfWeek.test(fields[4])
    );
};
