import {
    alpha,
    Box,
    Card,
    Divider,
    IconButton,
    LinearProgress,
    ListSubheader,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
    Dialog,
    DialogContent,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { CdmMigrationDetailedInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { useState } from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { MdListAlt, MdOutlineStorage, MdOutlineTimer } from "react-icons/md";
import { PiMagnifyingGlassPlusBold } from "react-icons/pi";
import { RiArrowDownDoubleLine, RiArrowLeftDoubleLine, RiArrowRightDoubleLine, RiArrowUpDoubleLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { ActionConfig, ActionMenuButton } from "../../../../common/actions/CommonActions";
import { DarkFlatCard } from "../../../../common/card/DarkCard";
import { TabConfig, TabGroup } from "../../../../common/tabs/TabComponents";
import { KeyValuePair, KeyValuePairSubtitle } from "../../../../common/text/CommonTypeFormats";
import { BlackTooltip } from "../../../../common/tooltip/ColorTooltip";
import { formatKnownDataType, formatProtoDurationObject, KnownDataType } from "../../../../common/utils/formatter";
import { useLinearProgressStyle } from "../../../cmcMigration/CmcMigrationCommon";
import { QueryResultWrapper } from "../../../core/data/QueryResultWrapper";
import { useIsDesktop } from "../../../layout/MainLayout";
import { ScreenContainer, ScreenTitleBar } from "../../../layout/ScreenCommon";
import { useGetCdmMigrationDetails } from "../../unstructured_data_hooks";
import { CdmSynchronizationStatusChip, getCdmSynchronizationStatusColor, getCdmSynchronizationStatusLabel } from "../CdmMigrationCommon";
import { CdmMigrationPassesList } from "./CdmMigrationPassesList";
import { DialogTopBar } from "../../../core/dialog/DialogComponents";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../../core/dialog/DialogService";
import { getStorageConnectionProtocolLabel, StorageConnectionTypeChip } from "../../connectedStorage/ConnectedStorageCommon";
import { CdmDataContainerStatusChip } from "../../UnstructuredDataCommon";

interface CdmMigrationDetailsProps {}

export const CdmMigrationDetailsScreen = (p: CdmMigrationDetailsProps) => {
    const { migrationId, projectId } = useParams();
    const migrationDetails = useGetCdmMigrationDetails(projectId, migrationId);
    const linearProgressStyle = useLinearProgressStyle(true);
    const isMobile = !useIsDesktop();
    const theme = useTheme();

    const actions: ActionConfig[] = [
        {
            id: "cancel",
            name: "Cancel",
            action: () => {
                console.log("Cancel");
            },
        },
        {
            id: "delete",
            name: "Delete",
            action: () => {
                console.log("Delete");
            },
        },
    ];

    const actionButton = (
        <ActionMenuButton
            actions={actions}
            title={"Migration Actions"}
            buttonLabel={"Migration Actions"}
            buttonProps={{
                variant: "contained",
                color: "secondary",
            }}
        />
    );

    const storageDetailTabs: TabConfig[] = [
        {
            label: "Passes",
            renderer: () => {
                return <CdmMigrationPassesList projectId={projectId} migrationId={migrationId} />;
            },
        },
        {
            label: "Data Synchronization",
            renderer: () => {
                return (
                    <Box>
                        <Typography>{"Data Synchronization"}</Typography>
                    </Box>
                );
            },
        },
        {
            label: "Object Analytics",
            renderer: () => {
                return (
                    <Box>
                        <Typography>{"Object Analytics"}</Typography>
                    </Box>
                );
            },
        },
        {
            label: "Settings",
            renderer: () => {
                return (
                    <Box>
                        <Typography>{"Settings"}</Typography>
                    </Box>
                );
            },
        },
    ];

    const [sidebarOpen, setSidebarOpen] = useState(true);
    const isLessThanLg = useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <ScreenContainer>
            <ScreenTitleBar title="CDM Migration Details" actions={actionButton} />
            <QueryResultWrapper queryResult={migrationDetails}>
                <Stack direction={"row"} spacing={2}>
                    <CdmSynchronizationStatusChip status={migrationDetails.data?.details?.migration?.status} />
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {"ID: "}
                        {migrationDetails.data?.details?.migration?.id}
                    </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1} pt={1}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {"Created: "}
                        {formatKnownDataType(migrationDetails.data?.details?.migration?.createdAt || 0, KnownDataType.DATE)}
                    </Typography>
                    {migrationDetails.data?.details?.migration?.startedAt && (
                        <Typography variant={"body2"} color={"textSecondary"}>
                            {"| Started: "}
                            {formatKnownDataType(migrationDetails.data?.details?.migration?.startedAt || 0, KnownDataType.DATE)}
                        </Typography>
                    )}
                    {migrationDetails.data?.details?.migration?.endedAt && (
                        <Typography color={"textSecondary"}>
                            {"| Completed: "}
                            {formatKnownDataType(migrationDetails.data?.details?.migration?.endedAt || 0, KnownDataType.DATE)}
                        </Typography>
                    )}
                </Stack>

                <Grid container spacing={2} pt={2}>
                    <Grid
                        size={{
                            xs: 12,
                            lg: 3,
                        }}
                    >
                        <Card elevation={0} sx={{ height: "100%" }}>
                            <Stack spacing={1} p={2}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography variant={"h6"}>{formatKnownDataType(0, KnownDataType.PERCENT)}</Typography>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {"Next Sync: "}
                                        {migrationDetails.data?.details?.migration?.nextScheduledPass
                                            ? formatKnownDataType(migrationDetails.data?.details?.migration?.nextScheduledPass, KnownDataType.DATE_RELATIVE)
                                            : "N/A"}
                                    </Typography>
                                </Stack>

                                <Box>
                                    <LinearProgress variant={"determinate"} value={50} sx={linearProgressStyle} />
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {"Overall Migration Progress"}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            lg: 9,
                        }}
                    >
                        <MigrationStorageDetailsCard migrationDetails={migrationDetails.data?.details} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} pt={2}>
                    <Grid
                        size={{
                            xs: 12,
                            lg: sidebarOpen ? 3 : 1,
                        }}
                    >
                        <Card elevation={0} sx={{ height: "100%" }}>
                            <Stack
                                direction={"row"}
                                justifyContent={isLessThanLg ? "space-between" : sidebarOpen ? "space-between" : "center"}
                                alignItems={"center"}
                            >
                                {isLessThanLg || sidebarOpen ? (
                                    <Box>
                                        <ListSubheader>{"Migration Overview"}</ListSubheader>
                                    </Box>
                                ) : null}

                                <Box p={2} onClick={() => setSidebarOpen(!sidebarOpen)}>
                                    {isLessThanLg ? (
                                        <IconButton>{sidebarOpen ? <RiArrowUpDoubleLine size={18} /> : <RiArrowDownDoubleLine size={18} />}</IconButton>
                                    ) : (
                                        <IconButton>{sidebarOpen ? <RiArrowLeftDoubleLine size={18} /> : <RiArrowRightDoubleLine size={18} />}</IconButton>
                                    )}
                                </Box>
                            </Stack>
                            <Box sx={{ display: sidebarOpen ? "block" : "none" }}>
                                <Box p={2}>
                                    <Typography fontWeight={"bold"}>{"Critical Status"}</Typography>
                                    <Card
                                        elevation={0}
                                        sx={{
                                            mt: 1,
                                            mb: 2,
                                            border: `2px solid`,
                                            borderColor: getCdmSynchronizationStatusColor(migrationDetails.data?.details?.mostRecentPass?.status),
                                            backgroundColor: alpha(
                                                getCdmSynchronizationStatusColor(migrationDetails.data?.details?.mostRecentPass?.status)(theme),
                                                0.1
                                            ),
                                            p: 2,
                                        }}
                                    >
                                        <Typography variant={"body2"}>
                                            {`${getCdmSynchronizationStatusLabel(migrationDetails.data?.details?.mostRecentPass?.status)} - Pass ${
                                                migrationDetails.data?.details?.mostRecentPass?.passNumber
                                            }`}
                                        </Typography>
                                    </Card>
                                    {migrationDetails.data?.details?.mostRecentPass?.startedAt ? (
                                        <KeyValuePair
                                            typographyVariant={"body2"}
                                            label={"Started"}
                                            value={formatKnownDataType(migrationDetails.data?.details?.mostRecentPass?.startedAt || 0, KnownDataType.DATE)}
                                        />
                                    ) : (
                                        <KeyValuePair
                                            typographyVariant={"body2"}
                                            label={"Scheduled for"}
                                            value={formatKnownDataType(migrationDetails.data?.details?.mostRecentPass?.scheduledAt || 0, KnownDataType.DATE)}
                                        />
                                    )}
                                    {migrationDetails.data?.details?.mostRecentPass?.endedAt && (
                                        <KeyValuePair
                                            typographyVariant={"body2"}
                                            label={"Completed"}
                                            value={formatKnownDataType(migrationDetails.data?.details?.mostRecentPass?.endedAt || 0, KnownDataType.DATE)}
                                        />
                                    )}
                                    <Stack direction={"row"} spacing={0.5} alignItems={"center"} pt={1}>
                                        <MdOutlineTimer />
                                        <KeyValuePair
                                            typographyVariant={"body2"}
                                            label={"Time Elapsed"}
                                            value={formatProtoDurationObject(migrationDetails.data?.details?.mostRecentPass?.totalSyncDuration)}
                                        />
                                    </Stack>
                                </Box>
                                <Divider />
                                <Box p={2}>
                                    <Typography fontWeight={"bold"}>{"Overall File Stats"}</Typography>
                                    <KeyValuePair typographyVariant={"body2"} label={"Objects Processed"} value={0} />
                                    <Box pt={2}>
                                        <Typography variant={"body2"} fontWeight={"bold"}>
                                            {"Error Counts"}
                                        </Typography>
                                        <KeyValuePair typographyVariant={"body2"} label={"Failed"} value={0} />
                                        <KeyValuePair typographyVariant={"body2"} label={"Retries"} value={0} />
                                    </Box>
                                    <Box pt={2}>
                                        <Typography variant={"body2"} fontWeight={"bold"}>
                                            {"Operations Breakdown"}
                                        </Typography>
                                        <KeyValuePair typographyVariant={"body2"} label={"Added"} value={0} />
                                        <KeyValuePair typographyVariant={"body2"} label={"Updated"} value={0} />
                                        <KeyValuePair typographyVariant={"body2"} label={"Skipped"} value={0} />
                                    </Box>
                                </Box>
                                <Divider />
                                <Box p={2}>
                                    <Typography fontWeight={"bold"}>{"Performance Metrics"}</Typography>
                                    <Box pt={2}>
                                        <Typography variant={"body2"} fontWeight={"bold"}>
                                            {"Transfer Rate"}
                                        </Typography>
                                        <Stack direction={"row"} spacing={2} alignItems={"center"} pt={1}>
                                            <DarkFlatCard>
                                                <Box p={2}>
                                                    <Typography variant={"h6"} lineHeight={1}>
                                                        {formatKnownDataType(0, KnownDataType.THROUGHPUT)}
                                                    </Typography>
                                                    <Typography variant={"body2"} color={"textSecondary"}>
                                                        {"Current"}
                                                    </Typography>
                                                </Box>
                                            </DarkFlatCard>
                                            <DarkFlatCard>
                                                <Box p={2}>
                                                    <Typography variant={"h6"} lineHeight={1}>
                                                        {formatKnownDataType(0, KnownDataType.THROUGHPUT)}
                                                    </Typography>
                                                    <Typography variant={"body2"} color={"textSecondary"}>
                                                        {"Average"}
                                                    </Typography>
                                                </Box>
                                            </DarkFlatCard>
                                        </Stack>
                                        <Box pt={1}>
                                            <KeyValuePair
                                                typographyVariant={"body2"}
                                                label={"Peak Rate"}
                                                value={formatKnownDataType(0, KnownDataType.THROUGHPUT)}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            lg: sidebarOpen ? 9 : 11,
                        }}
                    >
                        <TabGroup configs={storageDetailTabs} />
                    </Grid>
                </Grid>
            </QueryResultWrapper>
        </ScreenContainer>
    );
};

interface MigrationStorageDetailsCardProps {
    migrationDetails: CdmMigrationDetailedInfo.AsObject;
}

interface MigrationStorageDetailsDialogProps {
    migrationDetails: CdmMigrationDetailedInfo.AsObject;
    dialogState: DialogState;
}

const MigrationStorageDetailsDialog = (p: MigrationStorageDetailsDialogProps) => {
    const { migrationDetails, dialogState } = p;
    const isDialogFullScreen = useShouldDialogFullScreen();

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} maxWidth={"md"} fullWidth fullScreen={isDialogFullScreen}>
            <DialogTopBar dialogState={dialogState} title={"Migration Storage Details"} />
            <DialogContent>
                <Stack spacing={2}>
                    <DarkFlatCard>
                        <Box p={2}>
                            <Typography variant={"h6"}>{"Migration Details"}</Typography>
                            <Stack direction={"row"} pt={1} spacing={1}>
                                <Typography variant={"body2"} fontWeight={"bold"}>
                                    {"Migration ID:"}
                                </Typography>
                                <Typography variant={"body2"}>{migrationDetails.migration.id}</Typography>
                            </Stack>
                            <Stack direction={"row"} pt={1} spacing={1} alignItems={"center"}>
                                <Typography variant={"body2"} fontWeight={"bold"}>
                                    {"Migration Type:"}
                                </Typography>
                                <StorageConnectionTypeChip type={migrationDetails.sourceConnection?.protocol} />
                                <FaAngleDoubleRight size={18} />
                                <StorageConnectionTypeChip type={migrationDetails.destinationConnection?.protocol} />
                            </Stack>
                            <Stack direction={"row"} pt={1} spacing={1} alignItems={"center"}>
                                <Typography variant={"body2"} fontWeight={"bold"}>
                                    {"Base Path:"}
                                </Typography>
                                <Typography variant={"body2"}>{migrationDetails.migration?.basePath || "--"}</Typography>
                            </Stack>
                            <Stack direction={"row"} pt={1} spacing={1} alignItems={"center"}>
                                <Typography variant={"body2"} fontWeight={"bold"}>
                                    {"Excluded Paths:"}
                                </Typography>
                                <Typography variant={"body2"}>{migrationDetails.excludedPatternsList?.join(", ") || "--"}</Typography>
                            </Stack>
                        </Box>
                    </DarkFlatCard>
                    <Grid container spacing={2}>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <DarkFlatCard>
                                <Box p={2}>
                                    <Typography variant="h6" gutterBottom>{`Source`}</Typography>
                                    <Stack mb={2} spacing={1}>
                                        <Typography fontWeight={"bold"}>{"Storage Info"}</Typography>
                                        <KeyValuePair typographyVariant="body2" label={"Name"} value={migrationDetails.sourceStorage?.name || "N/A"} />
                                        <KeyValuePair typographyVariant="body2" label={"ID"} value={migrationDetails.sourceStorage.id || "N/A"} />
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <Typography fontWeight={"bold"}>{"Data Container"}</Typography>
                                        <KeyValuePair typographyVariant="body2" label={"Name"} value={migrationDetails.sourceDataContainer?.name || "N/A"} />
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Total Size"}
                                            value={formatKnownDataType(migrationDetails.sourceDataContainer?.totalCapacityBytes || 0, KnownDataType.CAPACITY)}
                                        />
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Free Space"}
                                            value={formatKnownDataType(
                                                (migrationDetails.sourceDataContainer?.totalCapacityBytes || 0) -
                                                    (migrationDetails.sourceDataContainer?.totalCapacityUsedBytes || 0),
                                                KnownDataType.CAPACITY
                                            )}
                                        />
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Status"}
                                            value={<CdmDataContainerStatusChip status={migrationDetails.sourceDataContainer?.status} />}
                                        />
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <Typography fontWeight={"bold"}>{"Storage Connection"}</Typography>
                                        <KeyValuePair typographyVariant="body2" label={"Server"} value={migrationDetails.sourceConnection?.address || "N/A"} />
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Type"}
                                            value={<StorageConnectionTypeChip type={migrationDetails.sourceConnection?.protocol} />}
                                        />
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <Typography fontWeight={"bold"}>{"Data Access Node"}</Typography>
                                        <KeyValuePair typographyVariant="body2" label={"Name"} value={migrationDetails.sourceDataAccessNode?.name || "N/A"} />
                                        <KeyValuePair typographyVariant="body2" label={"ID"} value={migrationDetails.sourceDataAccessNode?.id || "N/A"} />
                                    </Stack>
                                </Box>
                            </DarkFlatCard>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <DarkFlatCard>
                                <Box p={2}>
                                    <Typography variant="h6" gutterBottom>{`Destination`}</Typography>
                                    <Stack mb={2} spacing={1}>
                                        <Typography fontWeight={"bold"}>{"Storage Info"}</Typography>
                                        <KeyValuePair typographyVariant="body2" label={"Name"} value={migrationDetails.destinationStorage?.name || "N/A"} />
                                        <KeyValuePair typographyVariant="body2" label={"ID"} value={migrationDetails.destinationStorage.id || "N/A"} />
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <Typography fontWeight={"bold"}>{"Data Container"}</Typography>
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Name"}
                                            value={migrationDetails.destinationDataContainer?.name || "N/A"}
                                        />
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Total Size"}
                                            value={formatKnownDataType(
                                                migrationDetails.destinationDataContainer?.totalCapacityBytes || 0,
                                                KnownDataType.CAPACITY
                                            )}
                                        />
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Free Space"}
                                            value={formatKnownDataType(
                                                (migrationDetails.destinationDataContainer?.totalCapacityBytes || 0) -
                                                    (migrationDetails.destinationDataContainer?.totalCapacityUsedBytes || 0),
                                                KnownDataType.CAPACITY
                                            )}
                                        />
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Status"}
                                            value={<CdmDataContainerStatusChip status={migrationDetails.destinationDataContainer?.status} />}
                                        />
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <Typography fontWeight={"bold"}>{"Storage Connection"}</Typography>
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Server"}
                                            value={migrationDetails.destinationConnection?.address || "N/A"}
                                        />
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Type"}
                                            value={<StorageConnectionTypeChip type={migrationDetails.destinationConnection?.protocol} />}
                                        />
                                    </Stack>
                                    <Stack mb={2} spacing={1}>
                                        <Typography fontWeight={"bold"}>{"Data Access Node"}</Typography>
                                        <KeyValuePair
                                            typographyVariant="body2"
                                            label={"Name"}
                                            value={migrationDetails.destinationDataAccessNode?.name || "N/A"}
                                        />
                                        <KeyValuePair typographyVariant="body2" label={"ID"} value={migrationDetails.destinationDataAccessNode?.id || "N/A"} />
                                    </Stack>
                                </Box>
                            </DarkFlatCard>
                        </Grid>
                    </Grid>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

const MigrationStorageDetailsCard = (p: MigrationStorageDetailsCardProps) => {
    const { migrationDetails: migrationBasicInfo } = p;
    const dialogState = useDialogState();

    return (
        <Card elevation={0} sx={{ height: "100%" }}>
            <Grid container alignItems={"center"} justifyContent={"center"}>
                <Grid
                    size={{
                        xs: 5,
                    }}
                >
                    <Box p={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Box>
                            <MdOutlineStorage size={20} />
                        </Box>

                        <Box pl={2}>
                            <Typography variant={"h6"}>{migrationBasicInfo.sourceStorage?.name}</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {migrationBasicInfo.sourceDataContainer?.name}
                            </Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {migrationBasicInfo.sourceDataAccessNode?.name}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    size={{
                        xs: 1,
                        md: 1,
                    }}
                >
                    <Box display={"flex"} justifyContent={"center"}>
                        <FaAngleDoubleRight size={28} />
                    </Box>
                </Grid>
                <Grid
                    size={{
                        xs: 5,
                        md: 5,
                    }}
                >
                    <Box p={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Box>
                            <MdOutlineStorage size={20} />
                        </Box>
                        <Box pl={2}>
                            <Typography variant={"h6"}>{migrationBasicInfo.destinationStorage?.name}</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {migrationBasicInfo.destinationDataContainer?.name}
                            </Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {migrationBasicInfo.destinationDataAccessNode?.name}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    size={{
                        xs: 1,
                        md: 1,
                    }}
                >
                    <BlackTooltip title={"View Storage Details"}>
                        <Box display={"flex"} justifyContent={"center"}>
                            <IconButton onClick={dialogState.open}>
                                <PiMagnifyingGlassPlusBold />
                            </IconButton>
                        </Box>
                    </BlackTooltip>
                </Grid>
            </Grid>
            <MigrationStorageDetailsDialog migrationDetails={migrationBasicInfo} dialogState={dialogState} />
        </Card>
    );
};
