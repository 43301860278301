import { CreateCdmMigration } from "gc-web-proto/galaxycompletepb/apipb/cdmapipb/cdm_api_pb";
import { CdmQosSchedule, CdmBandwidthLimitSchedule, CdmReSyncSchedule, CdmStorageListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { DayOfWeek } from "gc-web-proto/galaxycompletepb/commonpb/common_pb";
import { create } from "zustand";
import { convertDateObjectToTimestamp } from "../../../../common/utils/formatter";
import { getMinutesFromTime } from "../../../../common/utils/dateUtil";

export interface BandwidthLimitSchedule {
    start: Date;
    end: Date;
    daysOfWeek: DayOfWeek[];
    bandwidthLimitMbps: number;
}

export interface CdmMigrationWizardState {
    sourceStorage: CdmStorageListItem.AsObject | null;
    sourceNodeId: string | null;
    sourceContainerId: string | null;
    sourceBasePath: string | null;
    destinationPath: string | null;
    destinationNodeId: string | null;
    destinationContainerId: string | null;
    destinationStorageId: string | null;
    timezone: string;
    bandwidthLimitSchedules: BandwidthLimitSchedule[];
    excludeFilePatterns: string[];
    reSyncFrequency: CdmReSyncSchedule.ReSyncFrequency;
    reSyncDays: DayOfWeek[];
    reSyncTime: Date;
    cronExpression: string;
    useCustomBandwidthLimit: boolean;
    setSourceStorage: (storage: CdmStorageListItem.AsObject) => void;
    setUseCustomBandwidthLimit: (useCustomBandwidthLimit: boolean) => void;
    setBandwidthLimitSchedules: (bandwidthLimitSchedules: BandwidthLimitSchedule[]) => void;
    setExcludeFilePatterns: (excludeFilePatterns: string[]) => void;
    setTimezone: (timezone: string) => void;
    setSourceNodeId: (dataAccessNodeId: string) => void;
    setSourceContainerId: (dataContainerId: string) => void;
    setSourceBasePath: (basePath: string) => void;
    setDestinationStorageId: (storageId: string) => void;
    setDestinationNodeId: (dataAccessNodeId: string) => void;
    setDestinationContainerId: (dataContainerId: string) => void;
    setDestinationPath: (basePath: string) => void;
    setReSyncFrequency: (frequency: CdmReSyncSchedule.ReSyncFrequency) => void;
    setReSyncDays: (days: DayOfWeek[]) => void;
    setReSyncTime: (time: Date) => void;
    setCronExpression: (expression: string) => void;
    createCdmMigrationRequest: (projectId: string) => CreateCdmMigration.Request;
}

export const useCdmMigrationWizardState = create<CdmMigrationWizardState>((set, get) => ({
    sourceStorage: null,
    sourceNodeId: null,
    sourceContainerId: null,
    sourceBasePath: null,
    destinationStorageId: null,
    destinationNodeId: null,
    destinationContainerId: null,
    destinationPath: null,
    timezone: "",
    reSyncFrequency: CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_UNSPECIFIED,
    reSyncDays: [],
    reSyncTime: new Date(),
    cronExpression: "",
    bandwidthLimitSchedules: [],
    excludeFilePatterns: [],
    useCustomBandwidthLimit: false,
    setSourceStorage: (storage: CdmStorageListItem.AsObject) => set({ sourceStorage: storage }),
    setUseCustomBandwidthLimit: (useCustomBandwidthLimit: boolean) => set({ useCustomBandwidthLimit }),
    setReSyncDays: (days: DayOfWeek[]) => set({ reSyncDays: days }),
    setReSyncFrequency: (frequency: CdmReSyncSchedule.ReSyncFrequency) => set({ reSyncFrequency: frequency }),
    setExcludeFilePatterns: (excludeFilePatterns: string[]) => set({ excludeFilePatterns }),
    setBandwidthLimitSchedules: (bandwidthLimitSchedules: BandwidthLimitSchedule[]) => set({ bandwidthLimitSchedules }),
    setCronExpression: (expression: string) => set({ cronExpression: expression }),
    setReSyncTime: (time: Date) => set({ reSyncTime: time }),
    setTimezone: (timezone: string) => set({ timezone: timezone }),
    setSourceNodeId: (dataAccessNodeId: string) => set({ sourceNodeId: dataAccessNodeId }),
    setSourceContainerId: (dataContainerId: string) => set({ sourceContainerId: dataContainerId }),
    setSourceBasePath: (basePath: string) => set({ sourceBasePath: basePath }),
    setDestinationStorageId: (storageId: string) => set({ destinationStorageId: storageId }),
    setDestinationNodeId: (dataAccessNodeId: string) => set({ destinationNodeId: dataAccessNodeId }),
    setDestinationContainerId: (dataContainerId: string) => set({ destinationContainerId: dataContainerId }),
    setDestinationPath: (basePath: string) => set({ destinationPath: basePath }),
    createCdmMigrationRequest: (projectId: string) => {
        const req = new CreateCdmMigration.Request()
            .setSourceNodeId(get().sourceNodeId)
            .setSourceStorageId(get().sourceStorage?.info?.id)
            .setSourceContainerId(get().sourceContainerId)
            .setBasePath(get().sourceBasePath || "/")
            .setDestinationNodeId(get().destinationNodeId)
            .setDestinationStorageId(get().destinationStorageId)
            .setDestinationContainerId(get().destinationContainerId)
            .setDestinationPath(get().destinationPath || "/")
            .setTimezone(get().timezone)
            .setProjectId(projectId);

        const resyncSchedule = new CdmReSyncSchedule().setFrequency(get().reSyncFrequency);

        if (get().reSyncFrequency === CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_ADVANCED) {
            resyncSchedule.setCronExpression(get().cronExpression);
        } else {
            resyncSchedule.setRunDaysList(get().reSyncDays);
            resyncSchedule.setStartAt(convertDateObjectToTimestamp(get().reSyncTime));
        }
        req.setReSyncSchedule(resyncSchedule);

        const qosSchedule = new CdmQosSchedule().setUseCustomBandwidthLimit(get().useCustomBandwidthLimit);

        if (get().bandwidthLimitSchedules.length > 0) {
            qosSchedule.setBandwidthLimitSchedulesList(
                get().bandwidthLimitSchedules.map((b) => {
                    return new CdmBandwidthLimitSchedule()
                        .setStart(getMinutesFromTime(b.start))
                        .setEnd(getMinutesFromTime(b.end))
                        .setDaysOfWeekList(b.daysOfWeek)
                        .setBandwidthLimitMbps(b.bandwidthLimitMbps);
                })
            );
        }
        req.setQosSchedule(qosSchedule);

        if (get().excludeFilePatterns.length > 0) {
            req.setExcludedPatternList(get().excludeFilePatterns);
        }

        return req;
    },
}));
