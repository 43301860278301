import { Chip, Theme } from "@mui/material";
import { CdmSynchronizationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_synchronization_status_pb";
import { IconBaseProps } from "react-icons";
import {
    MdCheckCircleOutline,
    MdOutlineRunCircle,
    MdRemoveCircleOutline,
    MdOutlineCircle,
    MdOutlinePauseCircleOutline,
    MdOutlinePlayCircle,
} from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";

interface CdmSynchronizationStatusChipProps {
    status: CdmSynchronizationStatus.CdmSynchronizationStatus;
}

export const CdmSynchronizationStatusChip: React.FC<CdmSynchronizationStatusChipProps> = (p) => {
    const { status } = p;

    return (
        <Chip
            icon={getCdmSynchronizationStatusIcon(status)}
            sx={{ backgroundColor: getCdmSynchronizationStatusColor(status) }}
            size={"small"}
            label={getCdmSynchronizationStatusLabel(status)}
        />
    );
};

export const getCdmSynchronizationStatusLabel = (status: CdmSynchronizationStatus.CdmSynchronizationStatus): string => {
    switch (status) {
        case CdmSynchronizationStatus.CdmSynchronizationStatus.PENDING:
            return "Pending";
        case CdmSynchronizationStatus.CdmSynchronizationStatus.STARTING:
            return "Starting";
        case CdmSynchronizationStatus.CdmSynchronizationStatus.RUNNING:
            return "Running";
        case CdmSynchronizationStatus.CdmSynchronizationStatus.SUSPENDED:
            return "Suspended";
        case CdmSynchronizationStatus.CdmSynchronizationStatus.WAITING:
            return "Waiting";
        case CdmSynchronizationStatus.CdmSynchronizationStatus.STOPPING:
            return "Stopping";
        case CdmSynchronizationStatus.CdmSynchronizationStatus.COMPLETED:
            return "Completed";
        case CdmSynchronizationStatus.CdmSynchronizationStatus.ERROR:
            return "Error";
        case CdmSynchronizationStatus.CdmSynchronizationStatus.FAILED:
            return "Failed";
        case CdmSynchronizationStatus.CdmSynchronizationStatus.CANCELLED:
            return "Cancelled";
        case CdmSynchronizationStatus.CdmSynchronizationStatus.UNKNOWN:
            return "Unknown";
        default:
            return "Unknown";
    }
};

export const getCdmSynchronizationStatusColor = (status: CdmSynchronizationStatus.CdmSynchronizationStatus) => {
    switch (status) {
        case CdmSynchronizationStatus.CdmSynchronizationStatus.COMPLETED:
            return (t: Theme) => t.palette.success.main;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.FAILED:
        case CdmSynchronizationStatus.CdmSynchronizationStatus.ERROR:
            return (t: Theme) => t.palette.error.main;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.RUNNING:
        case CdmSynchronizationStatus.CdmSynchronizationStatus.STARTING:
            return (t: Theme) => t.palette.info.main;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.CANCELLED:
        case CdmSynchronizationStatus.CdmSynchronizationStatus.STOPPING:
            return (t: Theme) => t.palette.grey["500"];
        case CdmSynchronizationStatus.CdmSynchronizationStatus.PENDING:
            return (t: Theme) => t.palette.grey["500"];
        case CdmSynchronizationStatus.CdmSynchronizationStatus.SUSPENDED:
            return (t: Theme) => t.palette.warning.main;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.WAITING:
            return (t: Theme) => t.palette.warning.main;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.UNKNOWN:
            return (t: Theme) => t.palette.grey["500"];
        default:
            return (t: Theme) => t.palette.grey["500"];
    }
};

export const getCdmSynchronizationStatusIcon = (status: CdmSynchronizationStatus.CdmSynchronizationStatus, props?: IconBaseProps) => {
    switch (status) {
        case CdmSynchronizationStatus.CdmSynchronizationStatus.COMPLETED:
            return <MdCheckCircleOutline {...props} />;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.FAILED:
        case CdmSynchronizationStatus.CdmSynchronizationStatus.ERROR:
            return <RiCloseCircleLine {...props} />;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.RUNNING:
        case CdmSynchronizationStatus.CdmSynchronizationStatus.STARTING:
            return <MdOutlineRunCircle {...props} />;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.CANCELLED:
            return <MdRemoveCircleOutline {...props} />;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.PENDING:
            return <MdOutlineCircle {...props} />;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.SUSPENDED:
            return <MdOutlinePauseCircleOutline {...props} />;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.WAITING:
            return <MdOutlinePlayCircle {...props} />;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.STOPPING:
            return <MdOutlinePauseCircleOutline {...props} />;
        case CdmSynchronizationStatus.CdmSynchronizationStatus.UNKNOWN:
            return <MdOutlineCircle {...props} />;
        default:
            return <MdOutlineCircle {...props} />;
    }
};
