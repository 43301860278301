// ======================
// CdmMigrationListScreen
// ======================

import { Box, Button, Card, LinearProgress, Link, ListSubheader, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { CdmMigrationListInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { CdmSynchronizationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_synchronization_status_pb";
import { useState } from "react";
import { DarkFlatCard } from "../../../common/card/DarkCard";
import { CdmQueryKeys } from "../../../common/QueryKeys";
import { QueryTable } from "../../../common/table/QueryTable";
import { TabConfig, TabGroup } from "../../../common/tabs/TabComponents";
import { BlackTooltip } from "../../../common/tooltip/ColorTooltip";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { useLinearProgressStyle } from "../../cmcMigration/CmcMigrationCommon";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { useListCdmMigrations } from "../unstructured_data_hooks";
import { useNavigateToCdmMigrationDetailsScreen, useNavigateToCdmMigrationWizard } from "../UnstructuredDataCommon";
import { CdmSynchronizationStatusChip, getCdmSynchronizationStatusLabel } from "./CdmMigrationCommon";

interface CdmMigrationListScreenProps {
    projectId: string;
}

export const CdmMigrationListScreen: React.FC<CdmMigrationListScreenProps> = (p) => {
    const { projectId } = p;
    const goToCdmMigrationWizard = useNavigateToCdmMigrationWizard();
    const migrations = useListCdmMigrations(projectId, 0, 1000, undefined, undefined);

    const tabConfigs: TabConfig[] = [
        {
            label: `All (${migrations.data?.summary?.migration?.totalMigrations || 0})`,
            renderer: () => {
                return <CdmMigrationListTable projectId={projectId} />;
            },
        },
        {
            label: `Pending (${migrations.data?.summary?.migration?.pendingMigrations || 0})`,
            renderer: () => {
                return <CdmMigrationListTable projectId={projectId} status={CdmSynchronizationStatus.CdmSynchronizationStatus.PENDING} />;
            },
        },
        {
            label: `Running (${migrations.data?.summary?.migration?.activeMigrations || 0})`,
            renderer: () => {
                return <CdmMigrationListTable projectId={projectId} status={CdmSynchronizationStatus.CdmSynchronizationStatus.RUNNING} />;
            },
        },
        {
            label: `Failed`,
            renderer: () => {
                return <CdmMigrationListTable projectId={projectId} status={CdmSynchronizationStatus.CdmSynchronizationStatus.FAILED} />;
            },
        },
        {
            label: `Completed (${migrations.data?.summary?.migration?.completedMigrations || 0})`,
            renderer: () => {
                return <CdmMigrationListTable projectId={projectId} status={CdmSynchronizationStatus.CdmSynchronizationStatus.COMPLETED} />;
            },
        },
    ];

    return (
        <ScreenContainer>
            <ScreenTitleBar
                title={"Migrations"}
                actions={
                    <OperatorView>
                        <Button onClick={goToCdmMigrationWizard} variant={"contained"} color={"secondary"}>
                            {`Create New Migration`}
                        </Button>
                    </OperatorView>
                }
            />
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        md: 4,
                    }}
                >
                    <Card sx={{ height: "100%" }}>
                        <ListSubheader>{`Migration Overview`}</ListSubheader>
                        <Box p={2} pt={0}>
                            <Grid container spacing={2}>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {migrations.data?.summary?.migration?.activeMigrations || 0}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Active"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {migrations.data?.summary?.migration?.pendingMigrations || 0}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Pending"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {migrations.data?.summary?.migration?.pausedMigrations || 0}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Paused"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {migrations.data?.summary?.migration?.totalMigrations || 0}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Total"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 4,
                    }}
                >
                    <Card sx={{ height: "100%" }}>
                        <ListSubheader>{`Data Overview`}</ListSubheader>
                        <Box p={2} pt={0}>
                            <Grid container spacing={2}>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {formatKnownDataType(migrations.data?.summary?.data?.totalDataBytes || 0, KnownDataType.CAPACITY)}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Total Size"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {formatKnownDataType(migrations.data?.summary?.data?.totalDataTransferredBytes || 0, KnownDataType.CAPACITY)}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Data Transferred"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {formatKnownDataType(0, KnownDataType.THROUGHPUT)}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Current Speed"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {0}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Overall ETA"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 4,
                    }}
                >
                    <Card sx={{ height: "100%" }}>
                        <ListSubheader>{`Status`}</ListSubheader>
                        <Box p={2} pt={0}>
                            <Grid container spacing={2}>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {migrations.data?.summary?.status?.failedLast24Hours || 0}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Failed (last 24h)"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {migrations.data?.summary?.status?.errorsLast24Hours || 0}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Errors (last 24h)"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {migrations.data?.summary?.status?.currentlyInError || 0}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Currently In Error"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 6,
                                        md: 12,
                                        lg: 6,
                                    }}
                                >
                                    <DarkFlatCard>
                                        <Box p={2}>
                                            <Typography fontWeight={600} variant={"h5"}>
                                                {formatKnownDataType(migrations.data?.summary?.status?.successRate || 0, KnownDataType.PERCENT)}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {"Success Rate"}
                                            </Typography>
                                        </Box>
                                    </DarkFlatCard>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <Box pt={2}>
                <TabGroup configs={tabConfigs} />
            </Box>
        </ScreenContainer>
    );
};

interface CdmMigrationListTableProps {
    projectId: string;
    status?: CdmSynchronizationStatus.CdmSynchronizationStatus;
    nodeIdList?: string[];
}

export const CdmMigrationListTable: React.FC<CdmMigrationListTableProps> = (p) => {
    const { projectId, status, nodeIdList } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 1, pageSize: 30 });

    const cdmMigrationsList = useListCdmMigrations(projectId, pageIndex, pageSize, status, nodeIdList);

    const columnHelper = createColumnHelper<CdmMigrationListInfo.AsObject>();
    const linearProgressStyle = useLinearProgressStyle();

    const goToCdmMigrationDetails = useNavigateToCdmMigrationDetailsScreen();

    const columns = [
        columnHelper.accessor((r: CdmMigrationListInfo.AsObject) => r, {
            header: "Status",
            cell: (props) => {
                return (
                    <BlackTooltip
                        title={
                            <>
                                <Typography>{"Status Details"}</Typography>
                                <Typography variant={"caption"}>{`${getCdmSynchronizationStatusLabel(props.row.original.migration?.status)}`}</Typography>
                            </>
                        }
                    >
                        <Box>
                            <CdmSynchronizationStatusChip status={props.row.original.migration?.status} />
                        </Box>
                    </BlackTooltip>
                );
            },
        }),
        columnHelper.accessor((r: CdmMigrationListInfo.AsObject) => r.migration?.id, {
            header: "Migration",
            cell: (props) => {
                return (
                    <BlackTooltip
                        title={
                            <>
                                <Typography>{"Job Details"}</Typography>
                                <Stack direction={"column"}>
                                    <Typography variant={"caption"}>{`ID: ${props.getValue()}`}</Typography>
                                    <Typography variant={"caption"}>{`Created At: ${formatKnownDataType(
                                        props.row.original.migration?.createdAt,
                                        KnownDataType.DATE
                                    )}`}</Typography>
                                </Stack>
                            </>
                        }
                    >
                        <Box>
                            <Link onClick={() => goToCdmMigrationDetails(props.getValue())}>{props.getValue().slice(-4)}</Link>
                        </Box>
                    </BlackTooltip>
                );
            },
        }),
        columnHelper.accessor((r: CdmMigrationListInfo.AsObject) => r, {
            header: "Progress",
            cell: (props) => {
                return (
                    <BlackTooltip
                        title={
                            <>
                                <Typography>{"Progress Details"}</Typography>
                                <Typography variant={"caption"}>{`Overall Progress: 90%`}</Typography>
                            </>
                        }
                    >
                        <Box>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <Box width={100}>
                                    <LinearProgress sx={linearProgressStyle} value={50} variant={"determinate"} />
                                </Box>
                                <Typography variant={"caption"}>{`50%`}</Typography>
                            </Stack>
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {"1TB processed"}
                            </Typography>
                        </Box>
                    </BlackTooltip>
                );
            },
        }),
        columnHelper.accessor((r: CdmMigrationListInfo.AsObject) => r.mostRecentPass, {
            header: "Most Recent Pass",
            cell: (props) => {
                return (
                    <Box>
                        {props.getValue() ? (
                            <BlackTooltip
                                title={
                                    <>
                                        <Typography>{"Pass Details"}</Typography>
                                        <Stack direction={"column"}>
                                            <Typography variant={"caption"}>{`Pass ${props.getValue().passNumber}`}</Typography>
                                            <Typography variant={"caption"}>{`Started ${formatKnownDataType(
                                                props.getValue().startedAt,
                                                KnownDataType.DATE_RELATIVE
                                            )}`}</Typography>
                                            <Typography variant={"caption"}>{`Pass Status: ${getCdmSynchronizationStatusLabel(
                                                props.getValue().status
                                            )}`}</Typography>
                                        </Stack>
                                    </>
                                }
                            >
                                <Box>
                                    <Typography>{`Pass ${props.getValue().passNumber}`}</Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>{`Started ${formatKnownDataType(
                                        props.getValue().startedAt,
                                        KnownDataType.DATE_RELATIVE
                                    )}`}</Typography>
                                </Box>
                            </BlackTooltip>
                        ) : (
                            "--"
                        )}
                    </Box>
                );
            },
        }),
        columnHelper.accessor((r: CdmMigrationListInfo.AsObject) => r, {
            header: "Storage Path",
            cell: (props) => {
                return (
                    <BlackTooltip
                        title={
                            <>
                                <Typography>{"Storage Details"}</Typography>
                                <Stack direction={"column"}>
                                    <Typography variant={"caption"} fontWeight={600}>{`Source`}</Typography>
                                    <Typography variant={"caption"}>{`Storage: ${props.row.original.sourceStorage?.name}`}</Typography>
                                    <Typography variant={"caption"}>{`Data Access Node: ${props.row.original.sourceDataAccessNode?.name}`}</Typography>
                                    <Typography variant={"caption"}>{`Data Container: ${props.row.original.sourceDataContainer?.name}`}</Typography>
                                </Stack>
                                <Stack direction={"column"} pt={1}>
                                    <Typography variant={"caption"} fontWeight={600}>{`Destination`}</Typography>
                                    <Typography variant={"caption"}>{`Storage: ${props.row.original.destinationStorage?.name}`}</Typography>
                                    <Typography variant={"caption"}>{`Data Access Node: ${props.row.original.destinationDataAccessNode?.name}`}</Typography>
                                    <Typography variant={"caption"}>{`Data Container: ${props.row.original.destinationDataContainer?.name}`}</Typography>
                                </Stack>
                            </>
                        }
                    >
                        <div>
                            {props.row.original.sourceStorage?.name} {">"} {props.row.original.destinationStorage?.name}
                        </div>
                    </BlackTooltip>
                );
            },
        }),
        columnHelper.accessor((r: CdmMigrationListInfo.AsObject) => r, {
            header: "Time Remaining",
            cell: (props) => {
                return <div>{"--"}</div>;
            },
        }),
    ];

    return (
        <Box pt={1}>
            <QueryTable
                data={cdmMigrationsList.data?.itemsList}
                columns={columns}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                pageCount={cdmMigrationsList.data?.pagerMeta.totalPages}
                queryKey={CdmQueryKeys.listCdmMigrations}
                emptyTableConfig={{
                    title: "No migrations found",
                    message: "No migrations found for the project",
                }}
            />
        </Box>
    );
};
