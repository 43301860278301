import { Box, Card, LinearProgress, Stack, Typography } from "@mui/material";
import { PaginationState } from "@tanstack/react-table";
import { CdmMigrationPass } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { useState } from "react";
import { QueryListTable } from "../../../../common/table/QueryListTable";
import { KeyValuePair } from "../../../../common/text/CommonTypeFormats";
import { formatDurationFromSeconds, formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import { QueryResultWrapper } from "../../../core/data/QueryResultWrapper";
import { useListCdmMigrationPasses } from "../../unstructured_data_hooks";
import { CdmSynchronizationStatusChip } from "../CdmMigrationCommon";
import { DarkFlatCard } from "../../../../common/card/DarkCard";
import { useLinearProgressStyle } from "../../../cmcMigration/CmcMigrationCommon";
import Grid from "@mui/material/Grid2";

interface CdmMigrationPassesListProps {
    migrationId: string;
    projectId: string;
}

export const CdmMigrationPassesList = (props: CdmMigrationPassesListProps) => {
    const { projectId, migrationId } = props;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 1, pageSize: 30 });

    const migrationPasses = useListCdmMigrationPasses(projectId, migrationId, pageIndex, pageSize);

    return (
        <Box pt={2}>
            <QueryResultWrapper queryResult={migrationPasses}>
                <MostRecentPassCard data={migrationPasses.data?.itemsList[0]} />
                <QueryListTable
                    data={pageIndex === 1 ? migrationPasses.data?.itemsList.slice(1, pageSize - 1) : migrationPasses.data?.itemsList}
                    listComponent={MigrationPassCard}
                    pagination={{ pageIndex, pageSize }}
                    setPagination={setPagination}
                    pageCount={migrationPasses.data?.pagerMeta.totalPages}
                />
            </QueryResultWrapper>
        </Box>
    );
};

const MostRecentPassCard = (props: { data: CdmMigrationPass.AsObject }) => {
    const { data } = props;
    const progressStyle = useLinearProgressStyle();
    return (
        <Card sx={{ mb: 2 }} elevation={0}>
            <Box p={2}>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Typography variant={"h6"}>{`Pass ${data.passNumber} (Most Recent)`}</Typography>
                    <CdmSynchronizationStatusChip status={data.status} />
                </Stack>

                <Stack>
                    {data.startedAt ? (
                        <Typography variant={"body2"}>{`Started: ${formatKnownDataType(data.startedAt, KnownDataType.DATE)}`}</Typography>
                    ) : (
                        <Typography variant={"body2"}>{`Scheduled for: ${formatKnownDataType(data.scheduledAt, KnownDataType.DATE)}`}</Typography>
                    )}
                    {data.endedAt ? <Typography variant={"body2"}>{`Completed: ${formatKnownDataType(data.endedAt, KnownDataType.DATE)}`}</Typography> : null}
                </Stack>
                <Grid container spacing={2} pt={2}>
                    <Grid
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                    >
                        <DarkFlatCard>
                            <Box p={2}>
                                <Typography variant={"body2"} fontWeight={"bold"}>
                                    {"Objects Discovered"}
                                </Typography>
                                <Box pt={1}>
                                    <Typography variant={"body2"}>{`${data.totalExistingObjects} Objects`}</Typography>
                                    <Typography variant={"body2"}>{`${formatKnownDataType(data.totalExistingCapacity, KnownDataType.CAPACITY)}`}</Typography>
                                </Box>
                            </Box>
                        </DarkFlatCard>
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                    >
                        <DarkFlatCard>
                            <Box p={2}>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography variant={"body2"} fontWeight={"bold"}>
                                        {"Sync Status"}
                                    </Typography>
                                    <Typography variant={"caption"}>{`${formatKnownDataType(
                                        (data.totalDataSynced / data.totalDataScanned) * 100,
                                        KnownDataType.PERCENT
                                    )}`}</Typography>
                                </Stack>
                                <Box pt={1}>
                                    <LinearProgress sx={progressStyle} variant={"determinate"} value={(data.totalDataSynced / data.totalDataScanned) * 100} />
                                </Box>
                                <Stack direction={"column"}>
                                    <Typography variant={"caption"}>{`${formatKnownDataType(
                                        data.totalDataSynced,
                                        KnownDataType.CAPACITY
                                    )} / ${formatKnownDataType(data.totalDataScanned, KnownDataType.CAPACITY)}`}</Typography>
                                    <Typography variant={"caption"}>{`Current Sync Rate: ${formatKnownDataType(
                                        data.currentSyncRate,
                                        KnownDataType.THROUGHPUT
                                    )}`}</Typography>
                                </Stack>
                            </Box>
                        </DarkFlatCard>
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                    >
                        <DarkFlatCard sx={{ height: "100%" }}>
                            <Box p={2}>
                                <Typography variant={"body2"} fontWeight={"bold"}>
                                    {"Operations"}
                                </Typography>
                            </Box>
                        </DarkFlatCard>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};

const MigrationPassCard = (props: { data: CdmMigrationPass.AsObject }) => {
    const { data } = props;
    const timeElapsed = data.totalSyncDuration.seconds + data.totalScanDuration.seconds;
    return (
        <Card sx={{ mb: 2 }} elevation={0}>
            <Box p={2}>
                <Grid container spacing={2} alignItems={"center"} mb={2}>
                    <Grid>
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <Typography fontWeight={"bold"}>{`Pass ${data.passNumber}`}</Typography>
                            <CdmSynchronizationStatusChip status={data.status} />
                        </Stack>
                    </Grid>
                    <Grid>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            {`Started: ${formatKnownDataType(data.startedAt, KnownDataType.DATE)} · 
                    Completed: ${formatKnownDataType(data.endedAt, KnownDataType.DATE)} · Time Elapsed: ${formatDurationFromSeconds(timeElapsed)}`}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid>
                        <KeyValuePair typographyVariant={"body2"} value={data.totalExistingObjects} label={"Files Discovered"} />
                    </Grid>
                    <Grid>
                        <KeyValuePair
                            typographyVariant={"body2"}
                            value={formatKnownDataType(data.totalExistingCapacity, KnownDataType.CAPACITY)}
                            label={"Data Size"}
                        />
                    </Grid>
                    <Grid>
                        <KeyValuePair typographyVariant={"body2"} value={data.totalObjectSynced} label={"Files Processed"} />
                    </Grid>
                    <Grid>
                        <KeyValuePair
                            typographyVariant={"body2"}
                            value={formatKnownDataType(data.totalDataSynced, KnownDataType.CAPACITY)}
                            label={"Data Transferred"}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};
