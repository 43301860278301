// ======================
// ConnectedStorageListScreen
// ======================

import { Box, Button, Card, Link, Stack, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PaginationState } from "@tanstack/react-table";
import { CdmDataAccessNodeInfo, CdmStorageConnection, CdmStorageListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { CdmStorageProtocol } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_storage_protocol_pb";
import React, { useState } from "react";
import { MdLabelOutline, MdOutlineStorage } from "react-icons/md";
import { ActionConfig, ActionMenuButton } from "../../../common/actions/CommonActions";
import { OutlinedCard } from "../../../common/card/OutlinedCard";
import { LightDivider } from "../../../common/misc";
import { CdmQueryKeys } from "../../../common/QueryKeys";
import { QueryListTable } from "../../../common/table/QueryListTable";
import { BlackTooltip } from "../../../common/tooltip/ColorTooltip";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { useDialogState } from "../../core/dialog/DialogService";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { LabelChip } from "../../labels/labelCommon";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import {
    useDeleteCdmStorage,
    useDeleteCdmStorageConnection,
    useListCdmDataAccessNodes,
    useListCdmStorages,
    useRescanCdmStorage,
    useRescanCdmStorageConnection,
} from "../unstructured_data_hooks";
import { useNavigateToConnectedStorageDetailsScreen, useNavigateToConnectNewStorageScreen } from "../UnstructuredDataCommon";
import { EditConnectionDialog, EditStorageDialog, StorageConnectionTypeChip } from "./ConnectedStorageCommon";

interface ConnectedStorageListScreenProps {
    projectId: string;
}

export const ConnectedStorageListScreen: React.FC<ConnectedStorageListScreenProps> = (p) => {
    const { projectId } = p;
    const goToNewStorageWizard = useNavigateToConnectNewStorageScreen();
    const dataAccessNodes = useListCdmDataAccessNodes(projectId, 1, 1000);
    const dataAccessNodeCount = dataAccessNodes.data?.itemsList.length;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const connectedStorageList = useListCdmStorages(projectId, pageIndex, pageSize);
    const connectNewStorageButton = (
        <OperatorView>
            <Button variant={"contained"} color={"secondary"} onClick={goToNewStorageWizard}>
                {"Connect New Storage"}
            </Button>
        </OperatorView>
    );

    const actions = <>{connectedStorageList.data?.itemsList.length > 0 && connectNewStorageButton}</>;

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Connected Storage"} actions={actions} />
            {dataAccessNodeCount > 0 ? (
                <QueryListTable
                    grid
                    pageCount={connectedStorageList.data?.pagerMeta?.totalPages || 0}
                    pagination={{ pageIndex, pageSize }}
                    setPagination={setPagination}
                    refetch={connectedStorageList.refetch}
                    queryKey={CdmQueryKeys.listCdmStorages}
                    listComponent={ConnectedStorageCard}
                    componentProps={{ dataAccessNodes: dataAccessNodes.data?.itemsList || [] }}
                    data={connectedStorageList.data?.itemsList || []}
                    emptyTableConfig={{
                        title: "No Storage Connected",
                        actionButton: connectNewStorageButton,
                    }}
                />
            ) : (
                <Card>
                    <Stack pt={6} pb={6} direction={"column"} justifyContent={"center"}>
                        <Stack direction={"row"} justifyContent={"center"}>
                            <Stack direction={"column"} spacing={2}>
                                <Box textAlign={"center"}>
                                    <Typography variant={"h6"}>{"No Data Access Node Available"}</Typography>
                                    <Typography>{"A Data Access Node must be deployed before connecting storage."}</Typography>
                                </Box>
                                <OperatorView>
                                    <Box textAlign={"center"}>
                                        <Button variant={"contained"} color={"secondary"}>
                                            {"Deploy Data Access Node"}
                                        </Button>
                                    </Box>
                                </OperatorView>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            )}
        </ScreenContainer>
    );
};

// ======================
// ConnectedStorageCard
// ======================

interface ConnectedStorageCardProps {
    data: CdmStorageListItem.AsObject;
    componentProps?: {
        dataAccessNodes: Array<CdmDataAccessNodeInfo.AsObject>;
    };
}

const ConnectedStorageCard: React.FC<ConnectedStorageCardProps> = (p) => {
    const { data, componentProps } = p;
    const goToStorageDetails = useNavigateToConnectedStorageDetailsScreen(data.info.id);
    const rescanStorage = useRescanCdmStorage();
    const deleteStorage = useDeleteCdmStorage();
    const editStorageDialogState = useDialogState();
    const globalDialogState = useGlobalDialogState();

    const storageActions: ActionConfig[] = [
        {
            id: "rescan",
            name: "Rescan",
            action: async () => {
                await rescanStorage.mutateAsync({
                    projectId: data.info.projectId,
                    storageId: data.info.id,
                });
            },
        },
        {
            id: "edit",
            name: "Edit",
            action: () => {
                editStorageDialogState.open();
            },
        },
        {
            id: "delete",
            name: "Delete",
            action: async () => {
                const confirmed = await globalDialogState.addConfirmDialog({
                    title: "Delete Storage",
                    message: "Are you sure you want to delete this storage?",
                });
                if (confirmed) {
                    await deleteStorage.mutateAsync({
                        projectId: data.info.projectId,
                        storageId: data.info.id,
                    });
                }
            },
        },
    ];

    const smbConnections = data.connectionsList.filter((c) => c.protocol === CdmStorageProtocol.CdmStorageProtocol.SMB);
    const nfsConnections = data.connectionsList.filter((c) => c.protocol === CdmStorageProtocol.CdmStorageProtocol.NFS);
    const s3Connections = data.connectionsList.filter((c) => c.protocol === CdmStorageProtocol.CdmStorageProtocol.S3);

    const theme = useTheme();

    return (
        <Grid
            size={{
                xs: 12,
                sm: 6,
                md: 4,
                xl: 3,
            }}
        >
            <Card>
                <Box p={2}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <MdOutlineStorage size={48} />
                            <Box>
                                <Typography variant={"body2"} color={"textSecondary"}>
                                    {`id: #${data.info.id}`}
                                </Typography>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Link fontWeight={600} onClick={goToStorageDetails}>
                                        {data.info.name}
                                    </Link>
                                </Stack>
                            </Box>
                        </Stack>
                        <Box>
                            <ActionMenuButton actions={storageActions} />
                        </Box>
                    </Stack>
                    <Stack direction={"row"} spacing={1} pt={1} alignItems={"center"}>
                        {smbConnections.length > 0 && <StorageConnectionTypeChip type={CdmStorageProtocol.CdmStorageProtocol.SMB} />}
                        {nfsConnections.length > 0 && <StorageConnectionTypeChip type={CdmStorageProtocol.CdmStorageProtocol.NFS} />}
                        {s3Connections.length > 0 && <StorageConnectionTypeChip type={CdmStorageProtocol.CdmStorageProtocol.S3} />}
                        {data.labelsList.length > 0 && (
                            <BlackTooltip
                                title={
                                    <Box p={1}>
                                        <Stack spacing={1}>
                                            {data.labelsList.map((l) => (
                                                <LabelChip key={l.name} label={l.name} description={l.description} color={l.color} />
                                            ))}
                                        </Stack>
                                    </Box>
                                }
                                arrow
                            >
                                <Box pt={0.5}>
                                    <MdLabelOutline size={24} color={theme.palette.warning.main} />
                                </Box>
                            </BlackTooltip>
                        )}
                    </Stack>
                </Box>
                <LightDivider />
                <Box p={2}>
                    {smbConnections.length > 0 && (
                        <Box>
                            <Box pb={1}>
                                <Typography fontWeight={"600"}>{`SMB Connections (${smbConnections.length})`}</Typography>
                            </Box>
                            {smbConnections.map((c, i) => {
                                return (
                                    <ConnectionCard
                                        dataAccessNodes={componentProps?.dataAccessNodes || []}
                                        projectId={data.info.projectId}
                                        connection={c}
                                        key={i}
                                    />
                                );
                            })}
                        </Box>
                    )}
                    {nfsConnections.length > 0 && (
                        <Box pt={1}>
                            <Box pb={1}>
                                <Typography fontWeight={"600"}>{`NFS Connections (${nfsConnections.length})`}</Typography>
                            </Box>
                            {nfsConnections.map((c, i) => {
                                return (
                                    <ConnectionCard
                                        dataAccessNodes={componentProps?.dataAccessNodes || []}
                                        projectId={data.info.projectId}
                                        connection={c}
                                        key={i}
                                    />
                                );
                            })}
                        </Box>
                    )}
                    {s3Connections.length > 0 && (
                        <Box pt={1}>
                            <Box pb={1}>
                                <Typography fontWeight={"600"}>{`S3 Connections (${s3Connections.length})`}</Typography>
                            </Box>
                            {s3Connections.map((c, i) => {
                                return (
                                    <ConnectionCard
                                        dataAccessNodes={componentProps?.dataAccessNodes || []}
                                        projectId={data.info.projectId}
                                        connection={c}
                                        key={i}
                                    />
                                );
                            })}
                        </Box>
                    )}
                </Box>
            </Card>
            {editStorageDialogState.isOpen && <EditStorageDialog projectId={data.info.projectId} storage={data} dialogState={editStorageDialogState} />}
        </Grid>
    );
};

// ======================
// ConnectionCard
// ======================

interface ConnectionCardProps {
    connection: CdmStorageConnection.AsObject;
    dataAccessNodes: Array<CdmDataAccessNodeInfo.AsObject>;
    projectId: string;
}

export const ConnectionCard: React.FC<ConnectionCardProps> = (p) => {
    const { connection, dataAccessNodes, projectId } = p;
    const rescanConnection = useRescanCdmStorageConnection();
    const deleteConnection = useDeleteCdmStorageConnection();
    const editConnectionDialogState = useDialogState();
    const globalDialogState = useGlobalDialogState();

    const connectionActions = [
        {
            id: "rescan",
            name: "Rescan",
            action: async () => {
                await rescanConnection.mutateAsync({
                    projectId: projectId,
                    storageId: connection.storageId,
                    connectionId: connection.id,
                });
            },
        },
        {
            id: "edit",
            name: "Edit Connection",
            action: () => {
                editConnectionDialogState.open();
            },
        },
        {
            id: "delete",
            name: "Delete Connection",
            action: async () => {
                const confirmed = await globalDialogState.addConfirmDialog({
                    title: "Delete Connection",
                    message: "Are you sure you want to delete this connection?",
                });
                if (confirmed) {
                    await deleteConnection.mutateAsync({
                        projectId: projectId,
                        storageId: connection.storageId,
                        connectionId: connection.id,
                    });
                }
            },
        },
    ];
    return (
        <Box mb={1}>
            <OutlinedCard>
                <Box p={2}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>{connection.address}</Typography>
                        <ActionMenuButton actions={connectionActions} />
                    </Stack>
                </Box>
            </OutlinedCard>
            {editConnectionDialogState.isOpen && (
                <EditConnectionDialog dataAccessNodes={dataAccessNodes} projectId={projectId} connection={connection} dialogState={editConnectionDialogState} />
            )}
        </Box>
    );
};
