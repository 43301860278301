import React from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { StepperNavButtons } from "../../../../common/stepper/StepperComponents";
import { useConnectNewStorageWizardState } from "./ConnectNewStorageWizardState";
import { useCurrentProjectID } from "../../../project/CurrentProjectState";
import { SelectLabelsForm } from "../../../labels/SelectLabelsForm";
import { Label } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";
import { useAddCdmStorage } from "../../unstructured_data_hooks";
import { useNavigateToConnectedStorageListScreen } from "../../UnstructuredDataCommon";

interface ConnectNewStorageWizardStorageInfoStepProps {}

export const ConnectNewStorageWizardStorageInfoStep: React.FC<ConnectNewStorageWizardStorageInfoStepProps> = (p) => {
    const {} = p;
    const { makeCreateStorageRequest, selectedIntegration, resetState } = useConnectNewStorageWizardState();
    const setStorageNameInState = useConnectNewStorageWizardState((state) => state.setStorageName);
    const setVendorInState = useConnectNewStorageWizardState((state) => state.setVendor);
    const setDescriptionInState = useConnectNewStorageWizardState((state) => state.setDescription);
    const setLabelsInState = useConnectNewStorageWizardState((state) => state.setLabels);
    const storageNameInState = useConnectNewStorageWizardState((state) => state.storageName);
    const vendorInState = useConnectNewStorageWizardState((state) => state.vendor);
    const goToConnectedStoragePage = useNavigateToConnectedStorageListScreen();
    const projectId = useCurrentProjectID();
    const [storageName, setStorageName] = React.useState(storageNameInState || "");
    const [vendor, setVendor] = React.useState(vendorInState || "");
    const [description, setDescription] = React.useState("");
    const [labels, setLabels] = React.useState<Label.AsObject[]>([]);
    const addCdmStorage = useAddCdmStorage();

    const handleSave = async () => {
        const request = makeCreateStorageRequest(projectId);
        const res = await addCdmStorage.mutateAsync({
            projectId: projectId,
            request: request,
        });
        if (res) {
            resetState();
            goToConnectedStoragePage();
        }
    };

    return (
        <Stack direction={"column"} spacing={2}>
            <Box pb={2}>
                <Typography variant={"h5"}>{"Add Storage Information"}</Typography>
                <Typography>{"Enter the necessary information to connect to your storage."}</Typography>
            </Box>
            <TextField
                variant={"filled"}
                label={"Storage Name"}
                value={storageName}
                disabled={!!selectedIntegration}
                onChange={(e) => {
                    setStorageName(e.target.value);
                    setStorageNameInState(e.target.value);
                }}
                fullWidth
            />
            <TextField
                variant={"filled"}
                label={"Vendor"}
                value={vendor}
                disabled={!!selectedIntegration}
                onChange={(e) => {
                    setVendor(e.target.value);
                    setVendorInState(e.target.value);
                }}
                fullWidth
            />
            <TextField
                variant={"filled"}
                label={"Description (Optional)"}
                multiline
                rows={4}
                helperText={"Provide any details or notes about this storage."}
                value={description}
                onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionInState(e.target.value);
                }}
            />
            <SelectLabelsForm
                currentlySelectedLabels={labels}
                onClickLabel={(label) => {
                    if (!!labels.find((l) => l.name === label.name)) {
                        setLabels(labels.filter((l) => l.name !== label.name));
                        setLabelsInState(labels.filter((l) => l.name !== label.name));
                    } else {
                        setLabels([...labels, label]);
                        setLabelsInState([...labels, label]);
                    }
                }}
                projectId={projectId}
            />
            <StepperNavButtons
                nextButtonProps={{
                    onClick: handleSave,
                    disabled: !storageName || !vendor,
                    label: "Add CDM Storage",
                }}
            />
        </Stack>
    );
};
