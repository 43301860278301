import { Box, Button, Card, FormControl, FormControlLabel, IconButton, Radio, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DateTimePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import cronstrue from "cronstrue";
import { isAfter } from "date-fns";
import { CdmReSyncSchedule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { DayOfWeek } from "gc-web-proto/galaxycompletepb/commonpb/common_pb";
import { useState } from "react";
import { DarkFlatOutlinedCard } from "../../../../common/card/DarkCard";
import { SelectableBox } from "../../../../common/card/SelectableCard";
import { DeleteIcon } from "../../../../common/CommonIcons";
import { StepperNavButtons } from "../../../../common/stepper/StepperComponents";
import { getIsValidCronExpression } from "../../../../common/utils/dateUtil";
import { formatTitleCase } from "../../../../common/utils/formatter";
import { useCreateCdmMigration } from "../../unstructured_data_hooks";
import { useNavigateToCdmMigrationListScreen } from "../../UnstructuredDataCommon";
import { BandwidthLimitSchedule, useCdmMigrationWizardState } from "./CdmMigrationWizardState";

interface CdmMigrationWizardMigrationConfigStepProps {
    projectId: string;
}

export const CdmMigrationWizardMigrationConfigStep: React.FC<CdmMigrationWizardMigrationConfigStepProps> = (p) => {
    const { projectId } = p;
    const wizardState = useCdmMigrationWizardState();
    const createMigration = useCreateCdmMigration();
    const goToMigrationListScreen = useNavigateToCdmMigrationListScreen();

    const [timezone, setTimezone] = useState<string>(wizardState.timezone || "");
    const [reSyncFrequency, setReSyncFrequency] = useState<CdmReSyncSchedule.ReSyncFrequency>(CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_UNSPECIFIED);
    const [reSyncDays, setReSyncDays] = useState<DayOfWeek[]>(wizardState.reSyncDays || []);
    const [reSyncTime, setReSyncTime] = useState<Date>(wizardState.reSyncTime || null);
    const [cronExpression, setCronExpression] = useState<string>(wizardState.cronExpression || "* * * * *");
    const [useCustomBandwidthLimit, setUseCustomBandwidthLimit] = useState<boolean>(wizardState.useCustomBandwidthLimit || false);

    const handleTimezoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTimezone(e.target.value);
        wizardState.setTimezone(e.target.value);
    };

    const handleSetReSyncFrequency = (frequency: CdmReSyncSchedule.ReSyncFrequency) => {
        setReSyncFrequency(frequency);
        wizardState.setReSyncFrequency(frequency);
        if (frequency === CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_ADVANCED) {
            setReSyncDays([]);
            setReSyncTime(null);
            wizardState.setReSyncDays([]);
            wizardState.setReSyncTime(null);
        }
        if (frequency !== CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_ADVANCED) {
            setCronExpression("");
            wizardState.setCronExpression("");
        }
    };

    const handleCronExpressionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCronExpression(e.target.value);
        wizardState.setCronExpression(e.target.value);
    };

    const handleCreateMigration = async () => {
        const req = wizardState.createCdmMigrationRequest(projectId);
        await createMigration.mutateAsync(req, {
            onSuccess: () => {
                goToMigrationListScreen();
            },
        });
    };

    const handleSetUseCustomBandwidthLimit = (useCustomBandwidthLimit: boolean) => {
        setUseCustomBandwidthLimit(useCustomBandwidthLimit);
        wizardState.setUseCustomBandwidthLimit(useCustomBandwidthLimit);
    };

    return (
        <Box>
            <Typography color={"textSecondary"}>{"Configure the migration settings."}</Typography>
            <Box pt={2}>
                <Typography variant={"h6"}>{"1. Re-sync Schedule"}</Typography>
                <Typography color={"textSecondary"}>
                    {`Set how often you want the migration to re-sync data between the source and destination. 
            Choose a preferred interval or customize your schedule.`}
                </Typography>
                <Box pt={2}>
                    <Card elevation={0}>
                        <Box p={2}>
                            <Typography fontWeight={"bold"}>{"Re-sync Frequency"}</Typography>
                            <Grid container spacing={2} pt={1}>
                                {Object.entries(CdmReSyncSchedule.ReSyncFrequency)
                                    .filter(([key, value]) => key !== "RESYNC_FREQUENCY_UNSPECIFIED")
                                    .map(([key, value]) => (
                                        <Grid key={key}>
                                            <SelectableBox
                                                selected={reSyncFrequency === value}
                                                onSelect={() => handleSetReSyncFrequency(value as CdmReSyncSchedule.ReSyncFrequency)}
                                            >
                                                <Box p={1} pr={4} pl={4}>
                                                    <Typography>{formatTitleCase(key.split("_")[2])}</Typography>
                                                </Box>
                                            </SelectableBox>
                                        </Grid>
                                    ))}
                            </Grid>
                            {![
                                CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_ADVANCED,
                                CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_UNSPECIFIED,
                            ].includes(reSyncFrequency) && (
                                <>
                                    <Box pt={2}>
                                        <Typography fontWeight={"bold"}>{"Run on these days:"}</Typography>
                                        <Grid container spacing={2} pt={1}>
                                            {Object.entries(DayOfWeek)
                                                .filter(([key, value]) => key !== "DAY_OF_WEEK_UNSPECIFIED")
                                                .map(([key, value]) => (
                                                    <Grid key={key}>
                                                        <SelectableBox
                                                            selected={reSyncDays.includes(value as DayOfWeek)}
                                                            onSelect={() => {
                                                                setReSyncDays([...reSyncDays, value as DayOfWeek]);
                                                                wizardState.setReSyncDays([...reSyncDays, value as DayOfWeek]);
                                                            }}
                                                            onDeselect={() => {
                                                                setReSyncDays(reSyncDays.filter((day) => day !== (value as DayOfWeek)));
                                                                wizardState.setReSyncDays(reSyncDays.filter((day) => day !== (value as DayOfWeek)));
                                                            }}
                                                        >
                                                            <Box p={1} pr={4} pl={4}>
                                                                <Typography>{formatTitleCase(key).slice(0, 3)}</Typography>
                                                            </Box>
                                                        </SelectableBox>
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Box>
                                    <Stack spacing={2} pt={2}>
                                        <Typography fontWeight={"bold"}>{"Starting at this time:"}</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                value={reSyncTime}
                                                onChange={(v) => {
                                                    setReSyncTime(v);
                                                    wizardState.setReSyncTime(v);
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        variant: "filled",
                                                        label: "Start Time",
                                                        error: !isAfter(reSyncTime, new Date()),
                                                        helperText: !isAfter(reSyncTime, new Date()) ? "Start time must be after current time." : "",
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                    <Stack spacing={2} pt={2}>
                                        <Typography fontWeight={"bold"}>{"Summary"}</Typography>
                                        <Typography>
                                            {`The migration will run ${
                                                formatTitleCase(
                                                    Object.keys(CdmReSyncSchedule.ReSyncFrequency).find(
                                                        (key) =>
                                                            CdmReSyncSchedule.ReSyncFrequency[key as keyof typeof CdmReSyncSchedule.ReSyncFrequency] ===
                                                            reSyncFrequency
                                                    ) || ""
                                                ).split("_")[2]
                                            }${
                                                reSyncDays.length > 0
                                                    ? ` on ${Object.keys(DayOfWeek)
                                                          .filter((key) => reSyncDays.includes(DayOfWeek[key as keyof typeof DayOfWeek]))
                                                          .map((key) => formatTitleCase(`${key}s`))
                                                          .join(", ")}`
                                                    : ""
                                            }${reSyncTime ? ` starting on ${reSyncTime.toLocaleDateString()} at ${reSyncTime.toLocaleTimeString()}` : ""}.`}
                                        </Typography>
                                    </Stack>
                                </>
                            )}
                            {reSyncFrequency === CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_ADVANCED && (
                                <Stack spacing={2} pt={2}>
                                    <Typography fontWeight={"bold"}>{"Cron Expression"}</Typography>
                                    <TextField
                                        label={"Cron Expression"}
                                        value={cronExpression}
                                        onChange={handleCronExpressionChange}
                                        error={!getIsValidCronExpression(cronExpression)}
                                        helperText={"Enter a valid cron expression to customize the re-sync schedule."}
                                    />
                                    <Stack spacing={1} pt={2}>
                                        <Typography fontWeight={"bold"}>{"Summary"}</Typography>
                                        <Typography>
                                            {getIsValidCronExpression(cronExpression) ? cronstrue.toString(cronExpression) : "Invalid cron expression"}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            )}
                        </Box>
                    </Card>
                </Box>
            </Box>
            <Stack spacing={2} pt={2}>
                <Typography variant={"h6"}>{"2. Timezone"}</Typography>
                <TextField variant={"filled"} label={"Timezone"} value={timezone} onChange={handleTimezoneChange} />
                {/* <FormControl>
                <InputLabel variant={"filled"}>Timezone</InputLabel>
                <Select variant={"filled"}>
                    <MenuItem value={"UTC"}>UTC</MenuItem>
                </Select>
            </FormControl> */}
            </Stack>
            <Box pt={2}>
                <Typography variant={"h6"}>{"3. QoS Configuration"}</Typography>
                <Typography color={"textSecondary"}>{`Manage bandwidth usage for your migration to optimize network performance.`}</Typography>
                <Stack spacing={1} pt={2}>
                    <FormControl>
                        <FormControlLabel
                            control={<Radio checked={useCustomBandwidthLimit === false} onChange={(e) => handleSetUseCustomBandwidthLimit(false)} />}
                            label={
                                <Box>
                                    <Typography>{"Use Default Bandwidth Limit"}</Typography>
                                    <Typography color={"textSecondary"}>{"Use the default bandwidth limit of unlimited for the migration."}</Typography>
                                </Box>
                            }
                        />
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            control={<Radio checked={useCustomBandwidthLimit === true} onChange={(e) => handleSetUseCustomBandwidthLimit(true)} />}
                            label={"Use Custom Bandwidth Limit"}
                        />
                    </FormControl>
                </Stack>
                {useCustomBandwidthLimit === true && <BandwidthLimitScheduleList />}
            </Box>
            <Box pt={2}>
                <Typography variant={"h6"}>{"4. Exclude File Patterns"}</Typography>
                <ExcludeFilePatternsList />
            </Box>
            <Box pt={2}>
                <StepperNavButtons
                    nextButtonProps={{
                        label: "Create Migration",
                        onClick: handleCreateMigration,
                        disabled: false,
                    }}
                />
            </Box>
        </Box>
    );
};

interface BandwidthLimitScheduleListProps {}

export const BandwidthLimitScheduleList: React.FC<BandwidthLimitScheduleListProps> = (p) => {
    const wizardState = useCdmMigrationWizardState();
    const [bandwidthLimitSchedules, setBandwidthLimitSchedules] = useState<BandwidthLimitSchedule[]>(wizardState.bandwidthLimitSchedules || []);

    const handleDeleteBandwidthLimitSchedule = (index: number) => {
        setBandwidthLimitSchedules(bandwidthLimitSchedules.filter((_, i) => i !== index));
        wizardState.setBandwidthLimitSchedules(bandwidthLimitSchedules.filter((_, i) => i !== index));
    };

    const handleSetScheduleStartTime = (index: number, start: Date) => {
        setBandwidthLimitSchedules(bandwidthLimitSchedules.map((schedule, i) => (i === index ? { ...schedule, start } : schedule)));
        wizardState.setBandwidthLimitSchedules(bandwidthLimitSchedules.map((schedule, i) => (i === index ? { ...schedule, start } : schedule)));
    };

    const handleSetScheduleEndTime = (index: number, end: Date) => {
        setBandwidthLimitSchedules(bandwidthLimitSchedules.map((schedule, i) => (i === index ? { ...schedule, end } : schedule)));
        wizardState.setBandwidthLimitSchedules(bandwidthLimitSchedules.map((schedule, i) => (i === index ? { ...schedule, end } : schedule)));
    };

    const handleSetScheduleDaysOfWeek = (index: number, daysOfWeek: DayOfWeek[]) => {
        setBandwidthLimitSchedules(bandwidthLimitSchedules.map((schedule, i) => (i === index ? { ...schedule, daysOfWeek } : schedule)));
        wizardState.setBandwidthLimitSchedules(bandwidthLimitSchedules.map((schedule, i) => (i === index ? { ...schedule, daysOfWeek } : schedule)));
    };

    const handleSetScheduleBandwidthLimitMbps = (index: number, bandwidthLimitMbps: string) => {
        setBandwidthLimitSchedules(
            bandwidthLimitSchedules.map((schedule, i) => (i === index ? { ...schedule, bandwidthLimitMbps: Number(bandwidthLimitMbps) } : schedule))
        );
        wizardState.setBandwidthLimitSchedules(
            bandwidthLimitSchedules.map((schedule, i) => (i === index ? { ...schedule, bandwidthLimitMbps: Number(bandwidthLimitMbps) } : schedule))
        );
    };

    return (
        <Stack spacing={2} pt={2}>
            {bandwidthLimitSchedules.map((bandwidthLimitSchedule, index) => (
                <BandwidthLimitScheduleCard
                    key={index}
                    bandwidthLimitSchedule={bandwidthLimitSchedule}
                    onDelete={() => handleDeleteBandwidthLimitSchedule(index)}
                    onSetScheduleStartTime={(start) => handleSetScheduleStartTime(index, start)}
                    onSetScheduleEndTime={(end) => handleSetScheduleEndTime(index, end)}
                    onSetScheduleDaysOfWeek={(daysOfWeek) => handleSetScheduleDaysOfWeek(index, daysOfWeek)}
                    onSetScheduleBandwidthLimitMbps={(bandwidthLimitMbps) => handleSetScheduleBandwidthLimitMbps(index, bandwidthLimitMbps.toString())}
                />
            ))}
            <Box>
                <Button
                    variant={"contained"}
                    onClick={() =>
                        setBandwidthLimitSchedules([
                            ...bandwidthLimitSchedules,
                            { start: new Date(0, 0, 0, 0, 0), end: new Date(0, 0, 0, 12, 0), daysOfWeek: [], bandwidthLimitMbps: 0 },
                        ])
                    }
                >
                    {`Add Bandwidth Limit Schedule`}
                </Button>
            </Box>
        </Stack>
    );
};

interface BandwidthLimitScheduleCardProps {
    bandwidthLimitSchedule: BandwidthLimitSchedule;
    onDelete: () => void;
    onSetScheduleStartTime: (start: Date) => void;
    onSetScheduleEndTime: (end: Date) => void;
    onSetScheduleDaysOfWeek: (daysOfWeek: DayOfWeek[]) => void;
    onSetScheduleBandwidthLimitMbps: (bandwidthLimitMbps: number) => void;
}

export const BandwidthLimitScheduleCard: React.FC<BandwidthLimitScheduleCardProps> = (p) => {
    const { bandwidthLimitSchedule, onDelete, onSetScheduleStartTime, onSetScheduleEndTime, onSetScheduleDaysOfWeek, onSetScheduleBandwidthLimitMbps } = p;
    const [start, setStart] = useState<Date>(bandwidthLimitSchedule.start);
    const [end, setEnd] = useState<Date>(bandwidthLimitSchedule.end);
    const [daysOfWeek, setDaysOfWeek] = useState<DayOfWeek[]>(bandwidthLimitSchedule.daysOfWeek);
    const [bandwidthLimitMbps, setBandwidthLimitMbps] = useState<number>(bandwidthLimitSchedule.bandwidthLimitMbps);

    const handleSetScheduleStartTime = (start: Date) => {
        setStart(start);
        onSetScheduleStartTime(start);
    };

    const handleSetScheduleEndTime = (end: Date) => {
        setEnd(end);
        onSetScheduleEndTime(end);
    };

    const handleSetScheduleDaysOfWeek = (daysOfWeek: DayOfWeek[]) => {
        setDaysOfWeek(daysOfWeek);
        onSetScheduleDaysOfWeek(daysOfWeek);
    };

    const handleSetScheduleBandwidthLimitMbps = (bandwidthLimitMbps: string) => {
        setBandwidthLimitMbps(parseInt(bandwidthLimitMbps));
        onSetScheduleBandwidthLimitMbps(parseInt(bandwidthLimitMbps));
    };

    return (
        <Card>
            <Box p={2}>
                <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
                    <Typography fontWeight={"bold"}>{"Bandwidth Limit Schedule"}</Typography>
                    <IconButton onClick={onDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
                <Stack spacing={2} pt={2}>
                    <Grid container spacing={2} pt={1}>
                        {Object.entries(DayOfWeek)
                            .filter(([key, value]) => key !== "DAY_OF_WEEK_UNSPECIFIED")
                            .map(([key, value]) => (
                                <Grid key={key}>
                                    <SelectableBox
                                        selected={daysOfWeek.includes(value as DayOfWeek)}
                                        onSelect={() => handleSetScheduleDaysOfWeek([...daysOfWeek, value as DayOfWeek])}
                                        onDeselect={() => {
                                            handleSetScheduleDaysOfWeek(daysOfWeek.filter((day) => day !== (value as DayOfWeek)));
                                        }}
                                    >
                                        <Box p={1} pr={4} pl={4}>
                                            <Typography>{formatTitleCase(key).slice(0, 3)}</Typography>
                                        </Box>
                                    </SelectableBox>
                                </Grid>
                            ))}
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            slotProps={{
                                textField: {
                                    variant: "filled",
                                    label: "Start Time",
                                    error: isAfter(start, end),
                                    helperText: isAfter(start, end) ? "Start time must be before end time" : "",
                                },
                            }}
                            value={start}
                            onChange={(v) => handleSetScheduleStartTime(v)}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                            slotProps={{
                                textField: {
                                    variant: "filled",
                                    label: "End Time",
                                    error: isAfter(start, end),
                                    helperText: isAfter(start, end) ? "End time must be after start time" : "",
                                },
                            }}
                            value={end}
                            onChange={(v) => handleSetScheduleEndTime(v)}
                        />
                    </LocalizationProvider>
                    <TextField
                        variant={"filled"}
                        label={"Bandwidth Limit (Mbps)"}
                        value={bandwidthLimitMbps}
                        onChange={(e) => handleSetScheduleBandwidthLimitMbps(e.target.value)}
                        helperText={"Enter a valid number equal to or greater than 0."}
                        error={bandwidthLimitMbps < 0 || bandwidthLimitMbps === null}
                    />
                </Stack>
            </Box>
        </Card>
    );
};

interface ExcludeFilePatternsListProps {}

export const ExcludeFilePatternsList: React.FC<ExcludeFilePatternsListProps> = (p) => {
    const wizardState = useCdmMigrationWizardState();
    const [excludeFilePatterns, setExcludeFilePatterns] = useState<string[]>(wizardState.excludeFilePatterns || []);

    const handleDeleteExcludeFilePattern = (index: number) => {
        setExcludeFilePatterns(excludeFilePatterns.filter((_, i) => i !== index));
        wizardState.setExcludeFilePatterns(excludeFilePatterns.filter((_, i) => i !== index));
    };

    const handleSetExcludeFilePattern = (index: number, excludeFilePattern: string) => {
        setExcludeFilePatterns(excludeFilePatterns.map((pattern, i) => (i === index ? excludeFilePattern : pattern)));
        wizardState.setExcludeFilePatterns(excludeFilePatterns.map((pattern, i) => (i === index ? excludeFilePattern : pattern)));
    };

    const handleAddExcludeFilePattern = () => {
        setExcludeFilePatterns([...excludeFilePatterns, ""]);
        wizardState.setExcludeFilePatterns([...excludeFilePatterns, ""]);
    };

    return (
        <Stack spacing={2} pt={2}>
            {excludeFilePatterns.map((excludeFilePattern, index) => (
                <ExcludeFilePatternCard
                    key={index}
                    excludeFilePattern={excludeFilePattern}
                    onDelete={() => handleDeleteExcludeFilePattern(index)}
                    onSetExcludeFilePattern={(excludeFilePattern) => handleSetExcludeFilePattern(index, excludeFilePattern)}
                />
            ))}
            <Box>
                <Button variant={"contained"} onClick={handleAddExcludeFilePattern}>
                    {`Add Exclude File Pattern`}
                </Button>
            </Box>
        </Stack>
    );
};

interface ExcludeFilePatternCardProps {
    excludeFilePattern: string;
    onDelete: () => void;
    onSetExcludeFilePattern: (excludeFilePattern: string) => void;
}

export const ExcludeFilePatternCard: React.FC<ExcludeFilePatternCardProps> = (p) => {
    const { excludeFilePattern, onDelete, onSetExcludeFilePattern } = p;

    const handleDeleteExcludeFilePattern = () => {
        onDelete();
    };

    const handleSetExcludeFilePattern = (excludeFilePattern: string) => {
        onSetExcludeFilePattern(excludeFilePattern);
    };

    return (
        <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"space-between"}>
            <TextField label={"Exclude File Pattern"} fullWidth value={excludeFilePattern} onChange={(e) => handleSetExcludeFilePattern(e.target.value)} />
            <IconButton onClick={handleDeleteExcludeFilePattern}>
                <DeleteIcon />
            </IconButton>
        </Stack>
    );
};
